
.carousel-item{
  height: 60vh;
}

  //category
.category{
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
    &__card {
        
        padding: 20px;
        text-align: center;
        cursor: pointer;
        transition: all .2s;
    }

    &__image {
        height: 50px;
        width: 50px;
        margin-bottom: 10px;
    }

    &__card:hover {
      transform: scale(1.08);
      background-color: #f3fdfb;
  }

    &__name {
        color: #444;;  
        font-size: 14px;  
        font-weight: bold;    
    }
}



.row {
  margin: 30px auto;
  padding: 40px;

  &__title{
    color:#000;
    font-weight: lighter;
  }

  &__content {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  
  &__content::-webkit-scrollbar{
    display: hidden !important;
  }

}


//ProductCard 

.ProductCard{
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border:1px solid #eee;
    border-radius: 10px;
    box-shadow: 0px 1px 0px 0px rgba(182,177,177,0.75);
    position: relative;

    &__image{
      height: 200px;
      width:200px;
      transition: all .5s;
      margin:20px auto;
    text-align: center;

    }

    &__image:hover{
      transform: scale(1.05);
    }

    &__name{
      font-weight: 600;
      color:#555;
      font-size: 14px;
      margin-bottom: 3px;
    }
    &__footer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width:100%;
    }
    &__cash{
      flex:2;
      font-weight: bold;
      color:#000;
      font-size: 13px;
      align-items: center;
    }
    &__btn{
      background-color: #eee;
      padding: 5px;
      text-decoration: none;
      font-size: 12px;
      text-align: center;
      transition: all .5s;
      color:#444;
      position: absolute;
      top:0;
      right:0;
      border-top-right-radius: 10px;

    }
    &__btn:hover{
        background-color: #000;
        color:#fff;
        justify-content: center;
        align-items: center;

    }
}
//StoreRow

.StoresRow{
  margin: 30px auto;
  padding: 40px;

  &__title{
    color:#000;
    font-weight: lighter;
  }

  &__contentInfo {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-evenly;
  }
}

//StoreCard

.StoreCard { 
  padding:10px;
  border-radius: 10px;
  position: relative;
  transition: all .5s;
  text-decoration: 'none';
  margin-bottom: 20px;
  border:1px solid #eee;

  &__top{
    width:100%;
    height:150px;
    background-color: #df9d9d;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 30px;
  }

  &__logo{
    width: 60px;
    height: 60px;
    border-radius:20;
    border:5px solid #fff;
    position: absolute;
    top:120px;
    left: 20px;
    z-index: 10;
    background-color: #eee;
    object-fit: contain;
  }
  &__name{
    font-weight: 700;
    color:#444;
    margin-bottom: 3px;
    margin-left: 10px;

  }
  &__category{
    color:#222;
    font-size: 12px;
    margin-bottom: 3px;
    margin-left: 10px;

    
  }
  &__addressLine{
    color:#222;
    font-size: 12px;
    margin-bottom: 3px;
    margin-left: 10px;

  }

  &__image{
    height: 150px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 30px;
  }
}

.StoreCard:hover {
  transform: scale(1.04);
}



//Review 

.Review {
  padding: 10px;
  border:1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  &__img{
    flex:1;
  }

  &__conten{

  }
  &__name{
  }
  &__message{}
  &__time{}
}

.footer {
  background-color: #000;
}

.bg-dark a{
  color:#fff;
}


.bg-dark a:hover{
  color:skyblue;
}

.bd-placeholder-img{
  
}

.d-none{
  display: block !important;
}