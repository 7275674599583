body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

Link {
  text-decoration: none !important;
}

/* login for  */



.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


/* productLayout */

.productLayout{
  /* border-bottom: 1px solid #eee; */
  margin-bottom: 0px !important;
}

.store_logo {
  height: 50px;
  width: 50px;
}

/* Reviews  */

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.text-white-50 { color: rgba(255, 255, 255, .5); }

.bg-purple { background-color: #6f42c1; }

.prdt_details {
  margin:50px auto;
}

.card-img-top{
  width: 100%;
  height: 200px;
  /* margin: 40px auto; */
}

.dp {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  margin-bottom: 30px;
}

.row__content::-webkit-scrollbar {
  display: none !important;
  
}

.img-responsive{
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
}
tr+tr {
  border-top: 1px solid #eee;
  padding: 10px;
}

tr {
  overflow-x: scroll;
}

.profilePage > .active {
  background-color: #f1f1f1;
  color:#555;
  font-size: 14px;
  font-weight: 700;
}

.categoryPage{
  max-height: 100vh;
}

.tableRow {
  box-shadow: -1px 0px 5px 0px rgba(176,165,165,0.75);
  margin-top: 10px;
  padding: 10px;
}

.tableRow td {

}

.detailsSection { 
}

.settingsSection {
  padding: 10px;
  margin-bottom: 1px solid #eee;
}

.sub_cate_list {
  margin-left: 10 !important;
  padding-left: 10px !important;
}

.sub_cate_list li {
  margin-left: 10 !important;
  padding-left: 10px !important;
  font-size: 14px;
  color:#555;
  text-transform: capitalize;
}
.col-ms-3-titleSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.col-ms-3-titleSection p {
  margin-bottom: -2px;
  margin-left: 5px;
}

.col-ms-3-titleSection img {
  background-color: #eee;
}

.productList {

  background-color: #f7f7f7;
  border-top:1px solid rgb(223, 207, 207);
  margin-bottom: 20px;
  padding: 20px;
}

.productListInner{
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}

.productListInner::-webkit-scrollbar{
  display: none !important;
}

.listItem{
  padding: 20px;
  text-align: center;
  text-decoration: none !important;
  color:#666;
  cursor: pointer;
}

.prdt_details h6 {
  color:#007bff;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
}

.storeCardItem {
  border:1px solid rgb(141, 134, 134);
  margin-bottom: 10px;
}

.storeCardItem  small {
  color:#666;
  font-size: 11px;
}

.infoData  p , .featureItem{
  font-size: 14px;
  color:#555;
  font-weight: 300;
  text-transform: capitalize;
}
.card-img-bottom{
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.category__name{
  font-size: 14px;
}

.itemKey {
  text-transform: capitalize;
}

.card-img-top1 {
  width: 50%;
  height:150px;
  object-fit: contain;
  margin: 20px auto;
  text-align: center;
}

.list-group-item {
  height: 100px;
  font-size: 14px;
}
.headerForCard:{
  height: 100px;
}